import { ElementsContextType, buildElementsContext, tryGetDataType } from '@medplum/core';
import { AccessPolicyResource } from '@medplum/fhirtypes';
import { useContext, useMemo, useState } from 'react';
import { ElementsInput } from '../ElementsInput/ElementsInput';
import { ElementsContext } from '../ElementsInput/ElementsInput.utils';
import { BaseInputProps } from '../ResourcePropertyInput/ResourcePropertyInput.utils';
import { maybeWrapWithContext } from '../utils/maybeWrapWithContext';

export interface BackboneElementInputProps extends BaseInputProps {
  /** Type name the backbone element represents */
  readonly typeName: string;
  /** (optional) The contents of the resource represented by the backbone element */
  readonly defaultValue?: any;
  /** (optional) callback function that is called when the value of the backbone element changes */
  readonly onChange?: (value: any) => void;
  /** (optional) Profile URL of the structure definition represented by the backbone element */
  readonly profileUrl?: string;
  /**
   * (optional) If provided, inputs specified in `accessPolicyResource.readonlyFields` are not editable
   * and inputs specified in `accessPolicyResource.hiddenFields` are not shown.
   */
  readonly accessPolicyResource?: AccessPolicyResource;
}

export function BackboneElementInput(props: BackboneElementInputProps): JSX.Element {
  const [defaultValue] = useState(() => props.defaultValue ?? {});
  const parentElementsContext = useContext(ElementsContext);
  const profileUrl = props.profileUrl ?? parentElementsContext?.profileUrl;
  const typeSchema = useMemo(() => tryGetDataType(props.typeName, profileUrl), [props.typeName, profileUrl]);
  const type = typeSchema?.type ?? props.typeName;

  if (type === 'ClientApplication' && typeSchema) {
    const filteredElements = ['name', 'description', 'secret', 'extension'];
    typeSchema.elements = Object.fromEntries(
      Object.entries(typeSchema.elements).filter(([key]) => filteredElements.includes(key))
    );

    const extensionElement = typeSchema.elements['extension'];
    if (extensionElement) {
      extensionElement.slicing = {
        discriminator: [{ path: 'extension', type: 'extension' }],
        ordered: true,
        rule: 'open',
        slices: [
          {
            name: 'ExternalProviders',
            path: 'Provider.path',
            definition: 'Add Internal Id of the provider',
            description: 'Add Internal Id of the provider',
            elements: {
              url: {
                description: 'URI for the provider',
                fixed: { type: 'uri', value: 'ExternalProviders' },
                path: 'Provider.path.url',
                min: 1,
                max: 1,
                type: [{ code: 'uri' }],
              },
              'value[x]': {
                path: 'Provider.path.value[x]',
                description: 'String Value for the provider',
                min: 1,
                max: 1,
                type: [{ code: 'string' }],
              },
              extension: {
                type: [{ code: 'Extension' }],
                max: 2,
                description: 'Add client Id and provider Id',
                path: 'Provider.path.extension',
                min: 0,
                slicing: {
                  discriminator: [{ path: 'url', type: 'value' }],
                  ordered: true,
                  rule: 'open',
                  slices: [
                    {
                      name: 'ClientId',
                      path: 'Provider.path.extension',
                      definition: 'Add client Id and Provider Id',
                      description: 'Add client Id and Provider Id',
                      elements: {
                        url: {
                          path: 'Provider.path.extension.url',
                          fixed: { type: 'uri', value: 'ClientId' },
                          description: '',
                          min: 1,
                          max: 1,
                          type: [{ code: 'uri' }],
                        },
                        'value[x]': {
                          path: 'Provider.path.extension.value[x]',
                          description: '',
                          min: 1,
                          max: 1,
                          type: [{ code: 'string' }],
                        },
                      },
                      min: 0,
                      max: 2,
                      type: [{ code: 'Extension' }],
                    },
                  ],
                },
              },
            },
            min: 0,
            max: 10,
            type: [{ code: 'Extension' }],
          },
        ],
      };
    }
  }

  const contextValue: ElementsContextType | undefined = useMemo(() => {
    if (!typeSchema) {
      return undefined;
    }
    return buildElementsContext({
      parentContext: parentElementsContext,
      elements: typeSchema.elements,
      path: props.path,
      profileUrl: typeSchema.url,
      accessPolicyResource: props.accessPolicyResource,
    });
  }, [typeSchema, parentElementsContext, props.path, props.accessPolicyResource]);

  if (!typeSchema) {
    return <div>{type}&nbsp;not implemented</div>;
  }

  return maybeWrapWithContext(
    ElementsContext.Provider,
    contextValue,
    <ElementsInput
      path={props.path}
      valuePath={props.valuePath}
      type={type}
      defaultValue={defaultValue}
      onChange={props.onChange}
      outcome={props.outcome}
    />
  );
}
