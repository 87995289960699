import { Button, Group, Stack, TextInput } from '@mantine/core';
import { normalizeOperationOutcome } from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import {
  Document,
  Form,
  getErrorsForInput,
  getIssuesForExpression,
  initRecaptcha,
  Logo,
  OperationOutcomeAlert,
} from '@medplum/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getConfig } from './config';

export function ResetPasswordPage(): JSX.Element {
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const [success, setSuccess] = useState(false);
  const recaptchaSiteKey = getConfig().recaptchaSiteKey;
  const ovokBaseUrl = getConfig().ovokBaseUrl;

  useEffect(() => {
    if (recaptchaSiteKey) {
      initRecaptcha(recaptchaSiteKey);
    }
  }, [recaptchaSiteKey]);

  return (
    <Document width={450}>
      <Form
        onSubmit={async (formData: Record<string, string>) => {
          await axios
            .post(`${ovokBaseUrl}/v2/auth/reset-password`, { email: formData.email, type: 'Practitioner' })
            .then(() => setSuccess(true))
            .catch((err) => setOutcome(normalizeOperationOutcome(err)));
        }}
      >
        <Stack gap="lg" mb="xl" align="center">
          <Logo size={32} />
        </Stack>
        <Stack gap="xl">
          <OperationOutcomeAlert issues={getIssuesForExpression(outcome, undefined)} />
          {!success && (
            <>
              <TextInput
                name="email"
                type="email"
                label="Email"
                required={true}
                autoFocus={true}
                error={getErrorsForInput(outcome, 'email')}
              />
              <Group justify="flex-end" mt="xl" wrap="nowrap">
                <Button type="submit">Reset password</Button>
              </Group>
            </>
          )}
          {success && <div>If the account exists on our system, a password reset email will be sent.</div>}
        </Stack>
      </Form>
    </Document>
  );
}
